import React from "react";
import { CustomInput, Table } from "reactstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

export default function QualityCriteriaList(props: any) {
  const { t } = useTranslation();
  return (
    <Table responsive hover>
      <thead>
        <tr>
          <th>{t("recipe.qualityCriteria")} </th>
          <th>{t("recipe.allowAppValidation")}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.criteriaList &&
          props.criteriaList.map((item: any, index: any) => {
            return (
              <tr key={item.uuid}>
                <td>{item.description}</td>
                <td>
                  <CustomInput
                    id={`${index}-checkbox`}
                    type="checkbox"
                    name={`${index}-checkbox`}
                    checked={item?.allow_app_validation}
                    disabled={true}
                  />
                </td>
                <td className="text-right text-nowrap ">
                  <span
                    className="px-1 pointer-cursor"
                    onClick={() => {
                      props.onEdit(item.uuid);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faEdit}
                      style={{
                        fontSize: 16,
                      }}
                    />
                  </span>

                  <span
                    className="px-1 pointer-cursor"
                    onClick={() => {
                      props.onDelete(item.uuid);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      style={{
                        fontSize: 16,
                      }}
                    />
                  </span>
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
}
