export const toggleItemInArray = (arr, item) => {
  const index = arr.indexOf(item);

  if (index === -1) {
    // If the item doesn't exist in the array, add it
    return [...arr, item];
  } else {
    // If the item exists in the array, remove it
    return [...arr.slice(0, index), ...arr.slice(index + 1)];
  }
};

export const generateRandomNumber = (numberOfDigits: number) => {
  if (numberOfDigits <= 0) {
    throw new Error("numberOfDigits must be greater than 0");
  }

  const min = Math.pow(10, numberOfDigits - 1);
  const max = Math.pow(10, numberOfDigits) - 1;

  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const snakeToCamel = (s: string): string => {
  return s
    .toLowerCase()
    .replace(/_./g, (match) => match.charAt(1).toUpperCase());
};
