import "../../../../styles/navigation-left.scss";

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Button, Collapse } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import commonService from "../../../../services/common.service";
import { USER_RIGHTS } from "../../../../constant/constant";

interface state {
  collapseOffering: boolean;
  collapseProduction: boolean;
  collapseQuality: boolean;
}
class RestaurantNavComponent extends Component<any, state> {
  userRightSupplierOfferingOnly: boolean = false;
  constructor(props: any) {
    super(props);
    this.state = {
      collapseOffering: false,
      collapseProduction: false,
      collapseQuality: false,
    };
    this.userRightSupplierOfferingOnly = commonService.hasOnlyPermission(
      USER_RIGHTS.SUPPLIER_OFFERING_ONLY,
      props.restaurantuuid
    );
  }
  checkSubMenu1(value: string) {
    const subMenus = [
      "ingredients",
      "recipe",
      "ingredient-class",
      "choice-item",
      "supplier-offering",
      "newIngredientList",
    ];
    return subMenus.indexOf(value) !== -1;
  }
  checkSubMenu2(value: string) {
    const subMenus = ["labels", "service-sets", "date-item"];
    return subMenus.indexOf(value) !== -1;
  }
  checkSubMenu3(value: string) {
    const subMenus = [
      "recipe-quality-criteria",
      "recipe-quality-groups",
      "recipe-quality-status",
    ];
    return subMenus.indexOf(value) !== -1;
  }
  toggle = (area: string) => {
    if (area === "offering") {
      this.setState({
        collapseOffering: !this.state.collapseOffering,
      });
    } else if (area === "quality") {
      this.setState({
        collapseQuality: !this.state.collapseQuality,
      });
    } else {
      this.setState({
        collapseProduction: !this.state.collapseProduction,
      });
    }
  };
  render() {
    const { t } = this.props;
    return (
      <div className="b-sticky-below-header b-side-navbar">
        <div className="child-btn-light mb-3">
          <div
            onClick={() => this.toggle("production")}
            className="pointer-cursor"
          >
            {t("common.production")}
            {!this.checkSubMenu1(this.props.display) && (
              <FontAwesomeIcon
                className="nav-icon-style d-block"
                pull="right"
                icon={
                  !this.state.collapseProduction &&
                  !this.checkSubMenu1(this.props.display)
                    ? faChevronDown
                    : faChevronUp
                }
              />
            )}
          </div>
          <Collapse
            isOpen={
              this.state.collapseProduction ||
              this.checkSubMenu1(this.props.display)
            }
          >
            {!this.userRightSupplierOfferingOnly && (
              <>
                <Link
                  to={`/restaurant/${this.props.restaurantuuid}/ingredients`}
                  className="mt-2 d-block"
                >
                  <Button
                    className={
                      this.props.display === "ingredients"
                        ? "child btn-dark"
                        : "child btn-light"
                    }
                  >
                    {t("article.articles")}
                  </Button>
                </Link>
                <Link
                  to={`/restaurant/${this.props.restaurantuuid}/recipes`}
                  className="mt-1 d-block"
                >
                  <Button
                    className={
                      this.props.display === "recipe"
                        ? "child btn-dark "
                        : "child btn-light"
                    }
                  >
                    {t("recipe.recipes")}
                  </Button>
                </Link>

                <Link
                  to={`/restaurant/${this.props.restaurantuuid}/choice-item`}
                  className="mt-1 d-block"
                >
                  <Button
                    className={
                      this.props.display === "choice-item"
                        ? "child btn-dark"
                        : "child btn-light"
                    }
                  >
                    {t("choiceItem.choiceItems")}
                  </Button>
                </Link>
                <Link
                  to={`/restaurant/${this.props.restaurantuuid}/ingredient-class`}
                  className="d-block mt-1"
                >
                  <Button
                    className={
                      this.props.display === "ingredient-class"
                        ? "btn-dark child"
                        : "btn-light child"
                    }
                  >
                    {t("ingredient.ingredientClass")}
                  </Button>
                </Link>
              </>
            )}
            <Link
              to={`/restaurant/${this.props.restaurantuuid}/supplierOffering`}
              className="mt-1 d-block"
            >
              <Button
                className={
                  this.props.display === "supplier-offering"
                    ? "child btn-dark "
                    : "child btn-light"
                }
              >
                {t("common.supplierOffering")}
              </Button>
            </Link>
          </Collapse>
        </div>
        {!this.userRightSupplierOfferingOnly && (
          <>
            <div className="child-btn-light mb-3">
              <div
                onClick={() => this.toggle("offering")}
                className="pointer-cursor"
              >
                {t("common.offering")}
                {!this.checkSubMenu2(this.props.display) && (
                  <FontAwesomeIcon
                    className="nav-icon-style  d-block"
                    pull="right"
                    icon={
                      !this.state.collapseOffering &&
                      !this.checkSubMenu2(this.props.display)
                        ? faChevronDown
                        : faChevronUp
                    }
                  />
                )}
              </div>
              <Collapse
                isOpen={
                  this.state.collapseOffering ||
                  this.checkSubMenu2(this.props.display)
                }
              >
                <Link
                  to={`/restaurant/${this.props.restaurantuuid}/labels`}
                  className="mt-1 d-block"
                >
                  <Button
                    className={
                      this.props.display === "labels"
                        ? "btn-dark child"
                        : "btn-light child"
                    }
                  >
                    {t("label.labels")}
                  </Button>
                </Link>{" "}
                <Link
                  to={`/restaurant/${this.props.restaurantuuid}/service-sets`}
                  className="mt-1 d-block"
                >
                  <Button
                    className={
                      this.props.display === "service-sets"
                        ? "btn-dark child"
                        : "btn-light child"
                    }
                  >
                    {t("serviceSet.serviceSets")}
                  </Button>
                </Link>
                <Link
                  to={`/restaurant/${this.props.restaurantuuid}/date-item`}
                  className="mt-1 d-block"
                >
                  <Button
                    className={
                      this.props.display === "date-item"
                        ? "child btn-dark"
                        : "child btn-light"
                    }
                  >
                    {t("common.dateSpecificOffering")}
                  </Button>
                </Link>
              </Collapse>
            </div>

            <Link to={`/restaurant/${this.props.restaurantuuid}/tags`}>
              <Button
                className={
                  this.props.display === "tags"
                    ? "btn-dark mb-3"
                    : "btn-light mb-3"
                }
              >
                {t("tag.tags")}
              </Button>
            </Link>
            <div className="child-btn-light mb-3">
              <div
                onClick={() => this.toggle("quality")}
                className="pointer-cursor"
              >
                {t("common.recipeQualityManagement")}
                {!this.checkSubMenu3(this.props.display) && (
                  <FontAwesomeIcon
                    className="nav-icon-style  d-block"
                    pull="right"
                    icon={
                      !this.state.collapseQuality &&
                      !this.checkSubMenu3(this.props.display)
                        ? faChevronDown
                        : faChevronUp
                    }
                  />
                )}
              </div>
              <Collapse
                isOpen={
                  this.state.collapseQuality ||
                  this.checkSubMenu3(this.props.display)
                }
              >
                <Link
                  to={`/restaurant/${this.props.restaurantuuid}/recipe-quality-criteria`}
                  className="mt-1 d-block"
                >
                  <Button
                    className={
                      this.props.display === "recipe-quality-criteria"
                        ? "btn-dark child"
                        : "btn-light child"
                    }
                  >
                    {t("common.recipeQualityCriteria")}
                  </Button>
                </Link>{" "}
                <Link
                  to={`/restaurant/${this.props.restaurantuuid}/recipe-quality-groups`}
                  className="mt-1 d-block"
                >
                  <Button
                    className={
                      this.props.display === "recipe-quality-groups"
                        ? "btn-dark child"
                        : "btn-light child"
                    }
                  >
                    {t("common.recipeQualityGroups")}
                  </Button>
                </Link>
                <Link
                  to={`/restaurant/${this.props.restaurantuuid}/recipe-quality-status`}
                  className="mt-1 d-block"
                >
                  <Button
                    className={
                      this.props.display === "recipe-quality-status"
                        ? "child btn-dark"
                        : "child btn-light"
                    }
                  >
                    {t("common.recipeQualityStatus")}
                  </Button>
                </Link>
              </Collapse>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default withTranslation()(withRouter(RestaurantNavComponent));
