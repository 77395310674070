import apiCall from "./api.call.config";

async function recipeQualityGroups(url: string, params: any) {
  try {
    return await apiCall.get(
      url + "/" + params.restaurantuuid + "/recipe-quality-group"
    );
  } catch (e) {
    throw e;
  }
}

async function addRecipeQualityGroup(url: string, params: any) {
  try {
    return await apiCall.post(
      url + "/" + params.restaurantuuid + "/recipe-quality-group",
      params.data
    );
  } catch (e) {
    throw e;
  }
}

async function removeRecipeQualityGroup(url: string, params: any) {
  try {
    return await apiCall.remove(
      url +
        "/" +
        params.restaurantuuid +
        "/recipe-quality-group/" +
        params.uuid
    );
  } catch (e) {
    throw e;
  }
}

async function updateRecipeQualityGroup(url: string, params: any) {
  try {
    return await apiCall.put(
      url +
        "/" +
        params.restaurantuuid +
        "/recipe-quality-group/" +
        params.uuid,
      params.data
    );
  } catch (e) {
    throw e;
  }
}

// eslint-disable-next-line
export default {
  recipeQualityGroups,
  addRecipeQualityGroup,
  updateRecipeQualityGroup,
  removeRecipeQualityGroup
};
