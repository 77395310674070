import React from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import MultiSelectList from "../select/multi-select-list";

interface props {
  isOpen: boolean;
  onCancel: any;
  onEdit: React.MouseEventHandler<any>;
  uuid?: string;
  recipeQualityCriterias: Array<any>;
  criterias: string[];
  onCriteriaChange: (uuids: string[]) => void;
}
const QualityStatusEditModal = (props: props) => {
  const { t } = useTranslation();

  return (
    <div>
      <Modal isOpen={props.isOpen}>
        <ModalHeader toggle={props.onCancel}>
          {t("recipe.assignQualityCriteria")}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row className="p-0">
              <Col sm={12}>
                <MultiSelectList
                  items={props.recipeQualityCriterias}
                  initialSelectedUuids={props.criterias}
                  onSelectionChange={props.onCriteriaChange}
                  pageSize={20}
                />
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.onCancel}>
            {t("common.cancel")}
          </Button>
          <Button
            color="info"
            onClick={props.onEdit}
            disabled={props.criterias?.length === 0}
          >
            {t("recipe.assignQualityCriteria")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default QualityStatusEditModal;
