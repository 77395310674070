import React from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import MultiSelectList from "../select/multi-select-list";

interface props {
  isOpen: boolean;
  onCancel: any;
  onSave: React.MouseEventHandler<any>;
  onEdit: React.MouseEventHandler<any>;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  uuid?: string;
  name?: string;
  mandatory?: boolean;
  recipeQualityCriterias: Array<any>;
  criterias: string[];
  onCriteriaChange: (uuids: string[]) => void;
}
const QualityGroupEditModal = (props: props) => {
  const { t } = useTranslation();

  return (
    <div>
      <Modal isOpen={props.isOpen}>
        <ModalHeader toggle={props.onCancel}>
          {props.uuid
            ? t("recipe.editRecipeQualityGroup")
            : t("recipe.addRecipeQualityGroup")}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row className="p-0">
              <Col sm={12}>
                <Label className="text-right" for="name">
                  {t("recipe.qualityGroup")}
                  <span className="required-star-style">*</span>
                </Label>
                <Input
                  type="text"
                  name="name"
                  value={props.name}
                  onChange={props.handleChange}
                />
              </Col>
            </FormGroup>
            <FormGroup row className="p-0 ml-2">
              <Col sm={12}>
                <Input
                  type="checkbox"
                  name="mandatory"
                  id="mandatory"
                  onChange={props.handleChange}
                  checked={props.mandatory}
                />
                <Label for="mandatory">
                  {t("recipe.mandatoryForAllRecipes")}
                </Label>
              </Col>
            </FormGroup>
            <FormGroup row className="p-0">
              <Col sm={12}>
                <Label>
                  {t("recipe.assignQualityCriteria")}
                  <span className="required-star-style">*</span>
                </Label>
                <MultiSelectList
                  items={props.recipeQualityCriterias}
                  initialSelectedUuids={props.criterias}
                  onSelectionChange={props.onCriteriaChange}
                  pageSize={20}
                />
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.onCancel}>
            {t("common.cancel")}
          </Button>
          <Button
            color="info"
            onClick={props.uuid ? props.onEdit : props.onSave}
            disabled={props.name === "" || props.criterias?.length === 0}
          >
            {t("recipe.saveRecipeQualityGroup")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default QualityGroupEditModal;
