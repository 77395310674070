import { sortBy } from "lodash";
import { Col, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import LoaderComponent from "../loader/loader";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import QualityStatusList from "./quality-status-list";
import commonService from "../../services/common.service";
import handleResponse from "../../services/response.service";
import { RESTAURANT_REQUEST } from "../../config/api.config";
import QualityStatusEditModal from "./quality-status-edit-modal";
import { RecipeQualityHelper } from "../../helpers/recipeQualityHelper";
import recipeQualityStatusService from "../../services/recipe.quality.status.service";
import NavigationRightComponent from "../navigation/navigation-right/navigation-right";
import ConceptHeaderComponent from "../navigation/navigation-header/concept-header/concept-header";
import RestaurantNavComponent from "../navigation/navigation-left/restaurant-navigation/restaurant-navigation";

type ParamTypes = {
  uuid: string;
};

const RecipeQualityStatusComponent = () => {
  const { uuid } = useParams<ParamTypes>();
  const { t } = useTranslation();
  const restaurantLang = commonService.getRestaurantDetails()?.languages || [];
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [statusList, setStatusList] = useState<Array<any>>([]);
  const [statusUuid, setStatusUuid] = useState<string>("");
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [recipeQualityCriterias, setRecipeQualityCriterias] = useState<
    Array<any>
  >([]);
  const [filteredCriterias, setFilteredCriterias] = useState<Array<any>>([]);
  const [criterias, setCriterias] = useState<string[]>([]);

  useEffect(() => {
    getStatusList();
    RecipeQualityHelper.getCriteriaList(
      uuid,
      setRecipeQualityCriterias,
      setIsFetching
    );
  }, [uuid]);

  const getStatusList = () => {
    setIsFetching(true);
    recipeQualityStatusService
      .recipeQualityStatuses(RESTAURANT_REQUEST, {
        restaurantuuid: uuid,
      })
      .then((response: any) => {
        setSortedQualityStatuses(response.data);
        setIsFetching(false);
      });
  };

  const setSortedQualityStatuses = (groupList: Array<any>) => {
    const items: Array<any> = [];
    groupList?.forEach((item: any) => {
      items.push({
        ...item,
        criterias: RecipeQualityHelper.setListLocale(item?.criterias),
      });
    });
    setStatusList(sortBy(items, "sort_order"));
  };

  const setEditModalToggle = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  const editQualityStatus: React.MouseEventHandler<any> = (event) => {
    const payLoad = {
      restaurantuuid: uuid,
      uuid: statusUuid,
      data: {
        criterias: criterias,
      },
    };
    recipeQualityStatusService
      .updateRecipeQualityStatus(RESTAURANT_REQUEST, payLoad)
      .then((response: any) => {
        handleResponse(response);
        if (response.ok) {
          setEditModalToggle();
          getStatusList();
        }
      });
  };

  const initEditQualityStatus = (statusUuid: string) => {
    setStatusUuid(statusUuid);
    const qualityStatus = statusList.find(
      (item, index) => item.uuid === statusUuid
    );
    setCriterias(qualityStatus?.criterias?.map((c: any) => c.uuid));
    setEditModalToggle();

    buildFilteredCriterias(statusUuid);
  };

  const buildFilteredCriterias = (statusUuid: string) => {
    let assignedCriterias: Array<any> = [];
    statusList.forEach((record: any) => {
      if (record.uuid !== statusUuid) {
        record?.criterias?.forEach((criteria: any) => {
          assignedCriterias.push(criteria.uuid);
        });
      }
    });

    setFilteredCriterias(
      recipeQualityCriterias.filter((c) => !assignedCriterias.includes(c.uuid))
    );
  };

  return (
    <div>
      <LoaderComponent display={isFetching} />

      <QualityStatusEditModal
        isOpen={isEditModalOpen}
        onCancel={setEditModalToggle}
        onEdit={editQualityStatus}
        uuid={statusUuid}
        recipeQualityCriterias={filteredCriterias}
        criterias={criterias}
        onCriteriaChange={setCriterias}
      />

      <div className="container-fluid">
        <ConceptHeaderComponent concept={"concept"} />
        <Row className="main light-theme">
          <Col xl={2} lg={3} className="hide-left-max">
            <RestaurantNavComponent
              display={"recipe-quality-status"}
              restaurantuuid={uuid}
            />
          </Col>
          <Col xl={8} lg={6}>
            <div className="white-box mb-3">
              <h4>{t("common.recipeQualityStatus")}</h4>
              <QualityStatusList
                restaurantLang={restaurantLang}
                statusList={statusList}
                onEdit={initEditQualityStatus}
              />
            </div>
          </Col>
          <Col xl={2} lg={3}>
            <NavigationRightComponent />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default RecipeQualityStatusComponent;
