import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import LoaderComponent from "../loader/loader";
import { useTranslation } from "react-i18next";
import QualityCriteriaList from "./quality-criteria-list";
import commonService from "../../services/common.service";
import { RESTAURANT_REQUEST } from "../../config/api.config";
import handleResponse from "../../services/response.service";
import QualityCriteriaEditModal from "./quality-criteria-edit-modal";
import { RecipeQualityHelper } from "../../helpers/recipeQualityHelper";
import DeleteModalCard from "../card-components/delete-card/delete-card";
import criteriaService from "../../services/recipe.quality.criteria.service";
import NavigationRightComponent from "../navigation/navigation-right/navigation-right";
import ConceptHeaderComponent from "../navigation/navigation-header/concept-header/concept-header";
import RestaurantNavComponent from "../navigation/navigation-left/restaurant-navigation/restaurant-navigation";

type ParamTypes = {
  uuid: string;
};

const RecipeQualityCriteriaComponent = () => {
  const { uuid } = useParams<ParamTypes>();
  const { t } = useTranslation();
  const restaurantLang = commonService.getRestaurantDetails()?.languages || [];
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState<boolean>(false);
  const [criteriaList, setCriteriaList] = useState<Array<any>>([]);
  const [criteriaUuid, setCriteriaUuid] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [allowAppValidation, setAllowAppValidation] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(true);

  useEffect(() => {
    getCriteriaList();
  }, [uuid]);

  const getCriteriaList = () => {
    setIsFetching(true);
    criteriaService
      .recipeQualityCriterias(RESTAURANT_REQUEST, {
        restaurantuuid: uuid,
      })
      .then((response: any) => {
        setCriteriaList(RecipeQualityHelper.setListLocale(response.data));
        setIsFetching(false);
      });
  };

  const setEditModalToggle = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  const saveQualityCriteria: React.MouseEventHandler<any> = (event) => {
    const payLoad = {
      restaurantuuid: uuid,
      data: {
        locales: {
          [restaurantLang[0].code]: {
            description: description,
          },
        },
        allow_app_validation: allowAppValidation,
      },
    };
    criteriaService
      .addRecipeQualityCriteria(RESTAURANT_REQUEST, payLoad)
      .then((response: any) => {
        handleResponse(response);
        if (response.ok) {
          setEditModalToggle();
          getCriteriaList();
        }
      });
  };

  const editQualityCriteria: React.MouseEventHandler<any> = (event) => {
    const payLoad = {
      restaurantuuid: uuid,
      uuid: criteriaUuid,
      data: {
        locales: {
          [restaurantLang[0].code]: {
            description: description,
          },
        },
        allow_app_validation: allowAppValidation,
      },
    };
    criteriaService
      .updateRecipeQualityCriteria(RESTAURANT_REQUEST, payLoad)
      .then((response: any) => {
        handleResponse(response);
        if (response.ok) {
          setEditModalToggle();
          getCriteriaList();
        }
      });
  };

  const addRecipeQualityCriteria = () => {
    setCriteriaUuid("");
    setDescription("");
    setAllowAppValidation(false);
    setEditModalToggle();
  };

  const initEditQualityCriteria = (criteriaUuid: string) => {
    setCriteriaUuid(criteriaUuid);
    const qualityCriteria = criteriaList.find(
      (item, index) => item.uuid === criteriaUuid
    );
    setDescription(qualityCriteria.description);
    setAllowAppValidation(qualityCriteria.allow_app_validation);
    setEditModalToggle();
  };

  const removeModalToggle = () => {
    setIsRemoveModalOpen(!isRemoveModalOpen);
  };

  const initRemoveQualityCriteria = (criteriaUuid: string) => {
    setCriteriaUuid(criteriaUuid);
    removeModalToggle();
  };

  const removeQualityCriteria: React.MouseEventHandler<any> = (event) => {
    const payLoad = {
      restaurantuuid: uuid,
      uuid: criteriaUuid,
    };
    criteriaService
      .removeRecipeQualityCriteria(RESTAURANT_REQUEST, payLoad)
      .then((response: any) => {
        handleResponse(response);
        if (response.ok) {
          removeModalToggle();
          getCriteriaList();
        }
      });
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.name === "description") {
      setDescription(event.target.value);
    }
    if (event.target.name === "allowAppValidation") {
      setAllowAppValidation(!allowAppValidation);
    }
  };

  return (
    <div>
      <LoaderComponent display={isFetching} />

      <QualityCriteriaEditModal
        isOpen={isEditModalOpen}
        onCancel={setEditModalToggle}
        onSave={saveQualityCriteria}
        onEdit={editQualityCriteria}
        handleChange={handleChange}
        uuid={criteriaUuid}
        description={description}
        allowAppValidation={allowAppValidation}
      />

      <DeleteModalCard
        isOpen={isRemoveModalOpen}
        uuid={criteriaUuid}
        okDelete={removeQualityCriteria}
        cancelDelete={removeModalToggle}
      />

      <div className="container-fluid">
        <ConceptHeaderComponent concept={"concept"} />
        <Row className="main light-theme">
          <Col xl={2} lg={3} className="hide-left-max">
            <RestaurantNavComponent
              display={"recipe-quality-criteria"}
              restaurantuuid={uuid}
            />
          </Col>
          <Col xl={8} lg={6}>
            <div className="white-box mb-3">
              <h4>
                {t("common.recipeQualityCriteria")}
                <Button
                  className="ml-2"
                  color="primary"
                  size="sm"
                  onClick={addRecipeQualityCriteria}
                >
                  {t("recipe.addRecipeQualityCriteria")}
                </Button>
              </h4>
              <QualityCriteriaList
                restaurantLang={restaurantLang}
                criteriaList={criteriaList}
                onEdit={initEditQualityCriteria}
                onDelete={initRemoveQualityCriteria}
              />
            </div>
          </Col>
          <Col xl={2} lg={3}>
            <NavigationRightComponent />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default RecipeQualityCriteriaComponent;
