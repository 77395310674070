import apiCall from "./api.call.config";

async function recipeQualityStatuses(url: string, params: any) {
  try {
    return await apiCall.get(
      url + "/" + params.restaurantuuid + "/recipe-quality-status"
    );
  } catch (e) {
    throw e;
  }
}

async function updateRecipeQualityStatus(url: string, params: any) {
  try {
    return await apiCall.put(
      url +
        "/" +
        params.restaurantuuid +
        "/recipe-quality-status/" +
        params.uuid,
      params.data
    );
  } catch (e) {
    throw e;
  }
}

// eslint-disable-next-line
export default {
  recipeQualityStatuses,
  updateRecipeQualityStatus,
};
