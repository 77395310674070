import { sortBy } from "lodash";
import commonService from "../services/common.service";
import { RESTAURANT_REQUEST } from "../config/api.config";
import recipeQualityGroupService from "../services/recipe.quality.group.service";
import recipeQualityCriteriaService from "../services/recipe.quality.criteria.service";

export const RecipeQualityHelper = {
  getCriteriaList: (
    uuid: string,
    listSetterCallaback: Function,
    fetchStatusCallaback: Function
  ) => {
    fetchStatusCallaback(true);
    recipeQualityCriteriaService
      .recipeQualityCriterias(RESTAURANT_REQUEST, {
        restaurantuuid: uuid,
      })
      .then((response: any) => {
        listSetterCallaback(RecipeQualityHelper.setListLocale(response.data));
        fetchStatusCallaback(false);
      });
  },

  setListLocale: (list: Array<any>, field: string = "description") => {
    const items: Array<any> = [];
    list?.forEach((item: any) => {
      items.push({
        ...item,
        [field]: commonService.applyLocalization(
          "restaurant",
          field,
          item?.locales
        )[field],
      });
    });

    return sortBy(items, field);
  },

  getGroupList: (
    uuid: string,
    listSetterCallaback: Function,
    fetchStatusCallaback: Function
  ) => {
    fetchStatusCallaback(true);
    recipeQualityGroupService
      .recipeQualityGroups(RESTAURANT_REQUEST, {
        restaurantuuid: uuid,
      })
      .then((response: any) => {
        listSetterCallaback(
          RecipeQualityHelper.setCriteriaLocale(
            RecipeQualityHelper.setListLocale(response.data, "name")
          )
        );
        fetchStatusCallaback(false);
      });
  },

  setCriteriaLocale: (list: Array<any>) => {
    const items: Array<any> = [];
    list?.forEach((item: any) => {
      items.push({
        ...item,
        criterias: RecipeQualityHelper.setListLocale(item?.criterias),
      });
    });

    return items;
  },
};
