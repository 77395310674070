import apiCall from "./api.call.config";

async function recipeQualityCriterias(url: string, params: any) {
  try {
    return await apiCall.get(
      url + "/" + params.restaurantuuid + "/recipe-quality-criteria"
    );
  } catch (e) {
    throw e;
  }
}

async function addRecipeQualityCriteria(url: string, params: any) {
  try {
    return await apiCall.post(
      url + "/" + params.restaurantuuid + "/recipe-quality-criteria",
      params.data
    );
  } catch (e) {
    throw e;
  }
}

async function removeRecipeQualityCriteria(url: string, params: any) {
  try {
    return await apiCall.remove(
      url +
        "/" +
        params.restaurantuuid +
        "/recipe-quality-criteria/" +
        params.uuid
    );
  } catch (e) {
    throw e;
  }
}

async function updateRecipeQualityCriteria(url: string, params: any) {
  try {
    return await apiCall.put(
      url +
        "/" +
        params.restaurantuuid +
        "/recipe-quality-criteria/" +
        params.uuid,
      params.data
    );
  } catch (e) {
    throw e;
  }
}

// eslint-disable-next-line
export default {
  recipeQualityCriterias,
  addRecipeQualityCriteria,
  updateRecipeQualityCriteria,
  removeRecipeQualityCriteria
};
