import "./app.scss";

import React, { useEffect, useMemo, useRef } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import AuthorizedRoute from "../pages/authorized-route/authorized-route";
import AuthorizedToken from "../pages/authorized-route/authorized-token";
import AuthorizedUser from "../pages/authorized-route/authorized-user";
import DevelopmentPage from "../pages/development/development";
import EmailConfirmationPage from "../pages/email-confirmation/email-confirmation";
import ForgotpasswordPage from "../pages/forgotpassword/forgotpassword";
import InventoryPage from "../pages/inventory/inventory";
import SupportPage from "../pages/support/support";
import LabelsPage from "../pages/labels/labels";
import ServiceSetPage from "../pages/serviceset/service-set";
import ServiceSetPageOverviewPage from "../pages/serviceset/service-set-overview";
import LoginPage from "../pages/login/login";
import LogoutPage from "../pages/logout/logout";

import MachinePage from "../pages/machine/machine";
import Page404 from "../pages/page404/page404";
import PosPage from "../pages/pos/pos";
import PrinterMessagePage from "../pages/printer-message/printer-message";
import PrinterProtocolPage from "../pages/printer-protocol/printer-protocol";
import PrinterPage from "../pages/printer/printer";
import RecipePrinterPage from "../pages/printer/recipe-printer";
import ProfileSettingPage from "../pages/profile-settings/profile-settings";
import ProfilePage from "../pages/profile/profile";
import RegistrationPage from "../pages/registration/registration";
import ResetPasswordPage from "../pages/reset-password/reset-password";
import RestaurantPage from "../pages/restaurant/restaurant";
import ChannelsPage from "../pages/channels/channels";
import channelsDetailsPage from "../pages/channels/channels-details";
import RolesAndRightsPage from "../pages/roles-rights/roles-rights";
import SuppliersPage from "../pages/suppliers/suppliers";
import RoomPage from "../pages/room/room";
import SettingsPage from "../pages/settings/settings";
import StationPage from "../pages/station/station";
import StoragePage from "../pages/storage/storage";
import TagsPage from "../pages/tags/tags";
import TransportTaskPage from "../pages/transport-task/transport-task";
import TranslationPage from "../pages/translation/translation";
import UnitsPage from "../pages/units/units";
import VatPage from "../pages/vat/vat";
import UserVerificationPage from "../pages/user-verification/user-verification";
import UsersPage from "../pages/users/users";
import WebViewPage from "../pages/web-view/web-view";
import { sagaMiddleware } from "../redux/store/store";
import rootSaga from "../saga/root.saga";
import ServerPage from "../pages/server/server";
import TablePage from "../pages/table/table";
import QRCodesPage from "../pages/qrcodes/qrcodes";
import ShopAccessPage from "../pages/shop-access/shop-access";
import QRCodeGenerate from "../components/qrcodes/qrcode-generate";
import ChoiceItemPage from "../pages/service-item/choice-item/choice-item";
import ChoiceItemDetailsPage from "../pages/service-item/choice-item/choice-item-details";
import abstractIngredientPage from "../pages/service-item/abstract-ingredient/abstract-ingredient";
import RecipePage from "../pages/service-item/recipe/recipe";
import RecipeDetailsPage from "../pages/service-item/recipe/recipe-details";

import abstractIngredientDetailsPage from "../pages/service-item/abstract-ingredient/abstract-ingredient-details";
import PaymentOptionsPage from "../pages/payment-options/payment-options";
import PrinterTemplatePage from "../pages/printer-template/printer-template-page";
import CourseListPage from "../pages/course-list/course-list";
import PeriodicityPage from "../pages/periodicity/periodicity";
import PeriodicityDetailsPage from "../pages/periodicity/periodicity-details";
import DateItemPage from "../pages/service-item/date-item/date-item";
import DateItemDetailsPage from "../pages/service-item/date-item/date-item-details";
import ServiceSetDetailsPage from "../pages/serviceset/service-set-details";
import { init, setFreshChatLanguage } from "./freshChat";
import { connect } from "react-redux";
import ContentManagementPage from "../pages/contentManagement/contentManagement";
import ReportsPage from "../pages/reports/reports";
import SupplierOfferingPage from "../pages/supplier-offering/supplier-offering";
import IngredientPage from "../pages/service-item/ingredient/ingredient";
import { ErrorBoundary } from "../pages/ErrorBoundary/ErrorBoundary";
import Home from "../pages/home/Home";
import ResourceCost from "../pages/resource-cost/resource-cost";
import mediaManager from "../pages/media-manager/media-manager";
import InventoryFilterSettingsPage from "../pages/inventory-filter-settings/Inventory-filter-settings";
import moment from "moment";
import { WEB_APP_CONFIG } from "../constant/constant";
import AppsPage from "../pages/apps/Apps";
import DevicesPage from "../pages/devices/Devices";
import ReportDetailsPage from "../pages/reports/reportDetails";
import EmailTemplatesPage from "../pages/email-templates/email-templates";
import RecipeLabelPrintPage from "../pages/service-item/recipe/recipe-label-print";
import StorageLabelPrintPage from "../pages/storage/storage-label-print";
import LocationLabelPrintPage from "../pages/storage/location-label-print";
import ShopInterfacePage from "../pages/shop-interface/shop-interface";
import RecipeQualityCriteriaPage from "../pages/recipe-quality-criteria/recipe-quality-criteria";
import RecipeQualityGroupPage from "../pages/recipe-quality-group/recipe-quality-group";
import RecipeQualityStatusPage from "../pages/recipe-quality-status/recipe-quality-status";

sagaMiddleware.run(rootSaga);

const App: React.FC = (props: any) => {
  let timerObject: any = useRef(0);

  useEffect(() => {
    init(props.chatInit);
    setFreshChatLanguage();
  }, [props.chatInit]);

  useEffect(() => {
    applyRefreshTime();
    return () => {
      clearTimeout(timerObject.current);
    };
  }, []);

  const applyRefreshTime = () => {
    const currentDate: any = new Date();
    const now: number = moment(currentDate).valueOf();
    const currentHour: any = moment(currentDate).hour();
    let newTime: any = 0;
    let refreshTime: any = 0;

    if (currentHour < WEB_APP_CONFIG.APP_DAILY_REFRESH_TIME) {
      newTime = moment(currentDate).set({ hour: 4, minute: 0, second: 0 });
    } else {
      newTime = moment(currentDate)
        .add(1, "days")
        .set({ hour: 4, minute: 0, second: 0 });
    }
    refreshTime = newTime.diff(now, "milliseconds");
    if (refreshTime > 0)
      timerObject.current = setTimeout(() => {
        window.location.reload();
      }, refreshTime);
  };

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Switch>
          <AuthorizedToken exact path="/" component={LoginPage} />
          <AuthorizedToken exact path="/login" component={LoginPage} />
          <AuthorizedToken
            exact
            path="/restaurant/:uuid/shoplogin"
            component={LoginPage}
          />

          <Route exact path="/development" component={DevelopmentPage} />

          <Route exact path="/registration" component={RegistrationPage} />
          <Route
            exact
            path="/registration/verify-email/:token"
            component={EmailConfirmationPage}
          />
          <Route
            exact
            path="/registration/verify-invitation/:token"
            component={UserVerificationPage}
          />
          <Route
            exact
            path="/registration/reset-password/:token/:protocol?"
            component={ResetPasswordPage}
          />

          <Route exact path="/forgotpassword" component={ForgotpasswordPage} />
          <Route
            exact
            path="/restaurant/:uuid/forgotpassword"
            component={ForgotpasswordPage}
          />

          <Route
            exact
            path="/webview/:navigate/:uuid?"
            component={WebViewPage}
          />

          <AuthorizedRoute
            exact
            path="/profile/:profile?"
            component={ProfilePage}
          />
          <AuthorizedRoute
            exact
            path="/profiles/settings/:profilesetting?"
            component={ProfileSettingPage}
          />

          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/home"
            component={Home}
          />
          <AuthorizedRoute
            exact
            path="/restaurant"
            component={RestaurantPage}
          />
          <AuthorizedUser exact path="/channels" component={ChannelsPage} />
          <AuthorizedUser
            exact
            path="/channels/:itemUuid/details"
            component={channelsDetailsPage}
          />

          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/pos/:pos?"
            component={PosPage}
          />

          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/settings/:settings?"
            component={SettingsPage}
          />

          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/recipes/:recipe?"
            component={RecipePage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/recipe/:recipeuuid/details"
            component={RecipeDetailsPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/choice-item"
            component={ChoiceItemPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/choice-item/:choiceuuid/details"
            component={ChoiceItemDetailsPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/date-item"
            component={DateItemPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/date-item/:dateItemUuid/details"
            component={DateItemDetailsPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/ingredient-class"
            component={abstractIngredientPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/ingredient-class/:abstractuuid/details"
            component={abstractIngredientDetailsPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/periodicity"
            component={PeriodicityPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/periodicity/:itemUuid/details"
            component={PeriodicityDetailsPage}
          />

          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/labels/:labels?"
            component={LabelsPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/service-sets-classic"
            component={ServiceSetPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/service-sets/"
            component={ServiceSetPageOverviewPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/service-sets/:itemUuid/details"
            component={ServiceSetDetailsPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/tags/:tags?"
            component={TagsPage}
          />

          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/recipe-quality-criteria"
            component={RecipeQualityCriteriaPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/recipe-quality-groups"
            component={RecipeQualityGroupPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/recipe-quality-status"
            component={RecipeQualityStatusPage}
          />

          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/units/:units?"
            component={UnitsPage}
          />

          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/vat/:vat?"
            component={VatPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/content-management/:vat?"
            component={ContentManagementPage}
          />

          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/payment-options/:paymentOptions?"
            component={PaymentOptionsPage}
          />

          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/printer-template/:printerTemplate?"
            component={PrinterTemplatePage}
          />

          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/course-list/:courseList?"
            component={CourseListPage}
          />

          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/transport/:transport?"
            component={TransportTaskPage}
          />

          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/printer-interfaces/:printer?"
            component={PrinterPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/recipe-printers/:recipePrinter?"
            component={RecipePrinterPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/printer/message/:message?"
            component={PrinterMessagePage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/printer/protocol/:protocol?"
            component={PrinterProtocolPage}
          />

          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/users/:users?"
            component={UsersPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/roles/:roles?"
            component={RolesAndRightsPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/apps/"
            component={AppsPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/devices/"
            component={DevicesPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/suppliers/:suppliers?"
            component={SuppliersPage}
          />

          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/inventory/:inventory?"
            component={InventoryPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/shop-access/"
            component={ShopAccessPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/qrcodes/"
            component={QRCodesPage}
          />
          <Route
            exact
            path="/restaurant/qrcodes/generate/:key"
            component={QRCodeGenerate}
          />
          <Route
            exact
            path="/restaurant/:uuid/reports/"
            component={ReportsPage}
          />
          <Route
            exact
            path="/restaurant/:uuid/reports/:report/details/:itemUuid"
            component={ReportDetailsPage}
          />
          <Route
            exact
            path="/restaurant/:uuid/reports/:report/:pageViewMode"
            component={ReportsPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/support/:support?"
            component={SupportPage}
          />

          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/infrastructure/tables/:table?"
            component={TablePage}
          />

          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/infrastructure/rooms/:room?"
            component={RoomPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/infrastructure/station/:station?"
            component={StationPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/infrastructure/machine/:machine?"
            component={MachinePage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/infrastructure/storage/:storage?"
            component={StoragePage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/servers/:servers?"
            component={ServerPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/translation/:translation?"
            component={TranslationPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/shop-interface"
            component={ShopInterfacePage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/supplierOffering"
            component={SupplierOfferingPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/supplierOffering/:pageViewMode?"
            component={SupplierOfferingPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/ingredients/:pageViewMode?"
            component={IngredientPage}
          />

          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/resource-cost"
            component={ResourceCost}
          />

          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/media-manager"
            component={mediaManager}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/inventory-filter-settings"
            component={InventoryFilterSettingsPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/email-templates/:template?"
            component={EmailTemplatesPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/recipe/:recipeUuid/print-label/:templateUuid"
            component={RecipeLabelPrintPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/storage/:storageUuid/print-label/:templateUuid"
            component={StorageLabelPrintPage}
          />
          <AuthorizedRoute
            exact
            path="/restaurant/:uuid/location/:storageType/:storageUuid/print-label/:templateUuid"
            component={LocationLabelPrintPage}
          />

          <Route exact path="/logout" component={LogoutPage} />

          <Route exact path="*" component={Page404} />
        </Switch>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

const mapStateToProps = (state: any) => {
  return { chatInit: state.chatInit.data, chatUpdate: state.chatUpdate.data };
};

export default connect(mapStateToProps)(App);
