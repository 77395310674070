import React, { useCallback, useEffect, useMemo } from "react";
import { UsersMenuLayout } from "../../components/users-menu-layout/users-menu-layout";
import { withTranslation, useTranslation } from "react-i18next";
import { withRouter, useParams } from "react-router-dom";
import LoaderComponent from "../../components/loader/loader";
import apiCall from "../../services/api.call.config";
import { RESTAURANT_REQUEST } from "../../config/api.config";
import useApi from "../../hooks/useApi";
import { DARK_GRAY, GREEN, RED } from "../../constant/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Apk, ClientDevice } from "../../Types/Apk";
import {
  addDeviceAppsModal,
  SubmitPayload,
} from "../../components/card-components/add-device-apps/add-device-apps-modal";
import { CustomInput } from "reactstrap";
import { MachineModel } from "../../models/machine.model";
import { optionList } from "../../components/inline-edit-input/inline-edit-input";
import commonService from "../../services/common.service";
import { OpenConfirmModal } from "../../components/card-components/confirm-card/confirm-modal";

const DevicesPage: React.FC = (props) => {
  const { uuid } = useParams<{ uuid: string }>();
  const { t } = useTranslation();

  const { request: postDeviceApps, loading: editLoading } = useApi(
    (
      device_uuid: string,
      data: { apps: string[]; show_on_home: boolean; autostart: boolean }
    ) =>
      apiCall.post(
        RESTAURANT_REQUEST + `/${uuid}/devices/${device_uuid}`,
        data
      ),
    true
  );

  const fetchData = useCallback(() => {
    return apiCall.get<ClientDevice[]>(RESTAURANT_REQUEST + `/${uuid}/devices`);
  }, [uuid]);
  const fetchApps = useCallback(() => {
    return apiCall.get<Apk[]>(RESTAURANT_REQUEST + `/${uuid}/apks`);
  }, [uuid]);
  const fetchMachines = useCallback(() => {
    return apiCall.get(RESTAURANT_REQUEST + `/${uuid}/machine`);
  }, [uuid]);
  // loadMetaData = () => {
  //     api
  //       .get(
  //         "/api/1.0/restaurant/" +
  //           this.props.match.params.uuid +
  //           "/get??sort=name:desc&components=unit, storage, label, room,station,machine",
  //         {}
  //       )
  //       .then((response: any) => {
  //         if (!!response.status) {
  //           const { room, storage } = response.data;
  //           const rooms: any = {};
  //
  //           room.forEach((r: any) => {
  //             rooms[r.uuid] = r;
  //             const name: string = commonService
  //               .applyLocalization("restaurant", "name", r.locales)
  //               ["name"].toLowerCase();
  //             rooms[r.uuid]["nameLower"] = name.toLowerCase();
  //             rooms[r.uuid]["name"] = name;
  //           });
  //           storage.forEach((st: any) => {
  //             if (!rooms[st.room_uuid]?.["storage"]) {
  //               rooms[st.room_uuid]["storage"] = [];
  //             }
  //             rooms[st.room_uuid]["storage"].push(st);
  //           });
  //
  //           this.setState({
  //             labels: response.data.label ? response.data.label : [],
  //             machineData: response.data.machine ? response.data.machine : [],
  //             rooms: _.sortBy(rooms, "nameLower"),
  //             stationData: response.data.station ? response.data.station : [],
  //             storages: response.data.storage ? response.data.storage : [],
  //             units: response.data.unit ? response.data.unit : [],
  //           });
  //         } else {
  //           handleResponse(response);
  //         }
  //       });
  //   };

  const {
    data: devices,
    loading: listLoading,
    request: refreshList,
  } = useApi<ClientDevice[]>(fetchData);
  const { data: apps, loading: appsLoading } = useApi<Apk[]>(fetchApps);
  const {
    data: machines,
    loading: machineLoading,
    request: refreshMachines,
  } = useApi(fetchMachines);

  const getInlineOptions = (optionsArray: Array<any>) => {
    let options: any = [];
    let items = _.map(optionsArray, (item: any) => {
      let label: any = commonService.applyLocalization(
        "restaurant",
        "name",
        item.locales
      )["name"];
      return {
        value: item.uuid,
        label: label,
      };
    });
    return options
      .concat({ value: "", label: t("common.select") })
      .concat(items);
  };

  const machineOptions = useMemo(() => {
    return getInlineOptions(machines ? machines.data : []);
  }, [machines]);

  useEffect(
    () => console.log("machine options", machineOptions),
    [machineOptions]
  );

  const openAddEditModal = async (device: ClientDevice) => {
    await addDeviceAppsModal({
      deviceUuid: device.uuid,
      onSubmit: submitForm,
      formData: { device: device },
      isEdit: true,
      appOptions: appOptions(device),
      selectedOptions: selectedAppOptions(device),
      machineOptions: machineOptions,
    });
  };

  const deleteDevice = async (device: ClientDevice) => {
    await OpenConfirmModal({
      title: t("common.delete_confirmation"),
    }).then(async (accepted) => {
      if (accepted) {
        await apiCall.post(RESTAURANT_REQUEST + `/${uuid}/delete-device/${device.uuid}`);
        commonService.toastService(
          t("common.removedSuccessFully"),
          "success"
        );
        refreshList();
      }
    });
  };

  const submitForm = async (payload: SubmitPayload) => {
    try {
      await postDeviceApps(payload.device_uuid, {
        apps: payload.apps,
        machine_uuid: payload.machine_uuid,
      });
      refreshList();
    } catch (er) {
      console.log("e", er);
    }
  };

  useEffect(() => {
    document.body.className = "light-theme";
  }, []);

  const renderCardActionButtons = (device: ClientDevice) => {
    return (
      <>
        <span
          className="pointer-cursor"
          data-toggle="tooltip"
          data-placement="left"
          title={t("common.edit")}
          onClick={() => openAddEditModal(device)}
        >
          <FontAwesomeIcon style={{ color: GREEN, margin: 5 }} icon={faEdit} />
        </span>
        <span
          className="pointer-cursor"
          data-toggle="tooltip"
          data-placement="left"
          title={t("common.delete")}
          onClick={() => deleteDevice(device)}
        >
          <FontAwesomeIcon style={{ color: RED, margin: 5 }} icon={faTrash} />
        </span>
      </>
    );
  };
  const deviceList = useMemo(() => devices?.data || [], [devices]);
  const appOptions = useCallback(
    (device: ClientDevice) => {
      const appList = apps?.data || [];
      return appList.map((a) => {
        const deviceApps = device.apps.map((app) => app.app.uuid);
        return {
          label: a.filename || a.package_name,
          value: a.uuid,
          isSelected: deviceApps.indexOf(a.uuid) > -1,
        };
      });
    },
    [apps]
  );
  const selectedAppOptions = useCallback(
    (device: ClientDevice) => {
      const appList = apps?.data || [];
      const deviceApps = device.apps.map((app) => app.app.uuid);
      const filtered = appList.filter(
        (app) => deviceApps.indexOf(app.uuid) > -1
      );

      return filtered.map((a) => {
        return {
          label: a.filename || a.package_name,
          value: a.uuid,
        };
      });
    },
    [apps]
  );

  return (
    <div>
      <LoaderComponent display={listLoading || appsLoading || editLoading} />
      <UsersMenuLayout display={"devices"} restaurantUuid={uuid}>
        <LoaderComponent display={false} />
        <div className="white-box mb-3">
          <h4>{t("devices.devices")}</h4>
          {deviceList.map((device) => (
            <section className="bf-service-item-collapse border rounded mb-3">
              <header className="d-flex align-items-center justify-content-between w-100">
                <div className="col-left d-flex align-items-center  px-3 py-2 w-100 cursor-pointer">
                  <h6
                    className="m-0"
                    style={{
                      color: DARK_GRAY,
                      paddingLeft: 10,
                    }}
                  >
                    <div>{`${device.device_name} - (${device.identifier})`}</div>
                  </h6>
                </div>
                <span className="d-none d-sm-flex  align-items-center">
                  {renderCardActionButtons(device)}
                </span>
              </header>
              <div className="d-flex flex-column px-5">
                {device.apps.map((app) => {
                  return (
                    <div className="d-flex">
                      <p className="col-2">
                        {app.app.filename || app.app.package_name}
                      </p>
                      <CustomInput
                        className="col-2"
                        id={`${app.value}-autostart`}
                        type="checkbox"
                        name={`${app.value}-autostart`}
                        label={t("devices.autostart")}
                        readOnly
                        disabled
                        checked={app.autostart}
                      />
                      <CustomInput
                        id={`${app.value}-showOnHome`}
                        type="checkbox"
                        name={`${app.value}-showOnHome`}
                        readOnly
                        disabled
                        label={t("devices.showOnHome")}
                        checked={app.show_on_home}
                      />
                    </div>
                  );
                })}
              </div>
            </section>
          ))}
        </div>
      </UsersMenuLayout>
    </div>
  );
};

export default withTranslation()(withRouter(DevicesPage));
