import React, { useEffect } from "react";
import RecipeQualityGroupComponent from "../../components/recipe-quality/recipe-quality-group";

const RecipeQualityGroupPage = () => {
  useEffect(() => {
    document.body.className = "light-theme";
  });

  return <RecipeQualityGroupComponent />;
};

export default RecipeQualityGroupPage;
