import React, { useEffect } from "react";
import RecipeQualityStatusComponent from "../../components/recipe-quality/recipe-quality-status";

const RecipeQualityStatusPage = () => {
  useEffect(() => {
    document.body.className = "light-theme";
  });

  return <RecipeQualityStatusComponent />;
};

export default RecipeQualityStatusPage;
