import React from "react";
import { useTranslation } from "react-i18next";
import { Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { snakeToCamel } from "../../helpers/commonHelper";

export default function QualityStatusList(props: any) {
  const { t } = useTranslation();
  return (
    <Table responsive hover>
      <thead>
        <tr>
          <th>{t("common.recipeQualityStatus")} </th>
          <th>{t("recipe.assignedQualityCriteria")}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.statusList &&
          props.statusList.map((item: any, index: any) => {
            return (
              <tr key={index}>
                <td style={{ color: item.colour, fontWeight: "bold" }}>
                  {t(`recipeStatus.${snakeToCamel(item.code)}`)}
                </td>
                <td>
                  <ul className="pl-3 mb-0">
                    {item?.criterias.map((c: any, i: any) => {
                      return (
                        <li key={i} className="bf-font-size-14">
                          {c.description}
                        </li>
                      );
                    })}
                  </ul>
                </td>
                <td className="text-right text-nowrap ">
                  {item.value > 3 && (
                    <span
                      className="px-1 pointer-cursor"
                      onClick={() => {
                        props.onEdit(item.uuid);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faEdit}
                        style={{
                          fontSize: 16,
                        }}
                      />
                    </span>
                  )}
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
}
